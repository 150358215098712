import { Controller } from "stimulus";

export default class extends Controller {

  static classes = ["stuck"];
  static values = {
    mode: String,
  };

  _magicElement = null;

  get _mode() {
    return this.hasModeValue ? this.modeValue : "top";
  }

  createMagicElement() {
    // Magic element placed next to the sticky el that acts as an external border.
    // When the magic element is off the page, we know that the sticky el is "stuck"
    if (this._magicElement !== null) {
      return;
    }

    this._magicElement = document.createElement("div");
    this._magicElement.setAttribute('sticky-magic-el', true);
    switch (this._mode) {
      case "top":
        this.element.insertAdjacentElement("beforebegin", this._magicElement);
        break;
      case "bottom":
        this.element.insertAdjacentElement("afterend", this._magicElement);
        break;
    }
  }

  connect() {
    let element = this.element;
    this.createMagicElement();

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.target !== this._magicElement) {
          return;
        }
        if (entry.intersectionRatio === 0) {
          element.classList.add(this.hasStuckClass ? this.stuckClass : "stuck");
        } else if (entry.intersectionRatio === 1) {
          element.classList.remove(this.hasStuckClass ? this.stuckClass : "stuck");
        }
      });

    }, {
      threshold: [0, 1],
    });
    observer.observe(this._magicElement);
  }

}