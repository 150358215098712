import { Controller } from "stimulus";
import dialogPolyfill from 'dialog-polyfill'

export default class DialogImageController extends Controller {

  static get classes() {
    return ["modal"];
  }

  static values = {
    src: String,
    srcSet: String,
    sizes: String,
  };

  get src() {
    return this.hasSrcValue ? this.srcValue : this.element.src;
  }

  get srcSet() {
    return this.hasSrcSetValue ? this.srcSetValue : this.element.srcset;
  }

  get sizes() {
    return this.hasSizesValue ? this.sizesValue : this.element.sizes;
  }

  initialize() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  connect() {
  }

  open() {
    if (this.dialog) {
      return;
    }
    this.dialog = document.createElement("dialog");

    let image = document.createElement("img");
    image.className = this.imageClassName;
    image.src = this.src;
    image.srcset = this.srcSet;
    image.sizes = this.sizes;
    this.dialog.appendChild(image);

    this.element.insertAdjacentElement("afterend", this.dialog);
    dialogPolyfill.registerDialog(this.dialog);
    this.dialog.classList.add("fixed");
    this.dialog.classList.add(this.hasModalClass ? this.modalClass : "image-lightbox");
    this.dialog.showModal();
    try {
      this.dialog.scrollIntoView({
        behavior: "smooth", block: "center", inline: "center",
      });
    } catch (e) {
      this.dialog.scrollIntoView();
    }
    this.dialog.addEventListener("click", this.close);
    this.dialog.addEventListener("cancel", this.close);
    this.dialog.addEventListener("close", this.close);
  }

  close() {
    if (this.dialog?.hasAttribute("open")) {
      this.dialog?.close();
    }

    // Just in-case dialog.close() hasn't already removed the dialogue
    this.dialog?.remove();
    this.dialog = undefined;

    try {
      this.element.scrollIntoView({
        behavior: "smooth", block: "center", inline: "center",
      });
    } catch (e) {
      this.element.scrollIntoView();
    }
  }

}