import { Controller } from 'stimulus';
import Plyr from "plyr";
import shaka from "shaka-player";
import Bowser from "bowser";

export default class extends Controller {

  static targets = [
    'safariStreamSource',
    'mainStreamSource',
    'nonStreamSource',
  ];

  // Streaming formats
  hlsDashFormats = [
    "mpd",
    "m3u8",
    "hls",
    "dash",
  ];

  get videoElement() {
    if (!this._video_element) {
      let element = this.element;
      this._video_element = element.tagName === 'VIDEO' ? element : element.querySelector('video');
    }
    return this._video_element;
  }

  get source() {
    if (!this._src_element) {
      if (this.userBrowser.name === "Safari" && this.hasSafariStreamSourceTarget) {
        this._src_element = this.safariStreamSourceTarget;
      } else if (this.hasMainStreamSourceTarget) {
        this._src_element = this.mainStreamSourceTarget;
      } else if (this.hasNonStreamSourceTarget) {
        this._src_element = this.nonStreamSourceTarget;
      } else {
        this._src_element = this.videoElement;
      }
    }
    return this._src_element;
  }

  getExtension(url) {
    return url.split('.').pop();
  }

  connect() {
    if (!this.videoElement) {
      return;
    }
    this.videoElement.classList.add('video-js');
    this.userBrowser = Bowser.getParser(window.navigator.userAgent).getBrowser();
    this.player = new Plyr(this.videoElement);
    if (this.hlsDashFormats.includes(this.getExtension(this.source.src))) {
      this.initShaka(this.videoElement);
    }
  }

  disconnect() {
    this.player.destroy();
  }

  // Shaka enables the player to play HLS and DASH stream formats
  async initShaka(videoElement) {
    if (!window.shaka) {
      window.shaka = shaka;
      shaka.polyfill.installAll(); // Install built-in polyfills

      if (!shaka.Player.isBrowserSupported()) {
        console.warn('Browser does not support adaptive video streaming, falling back to chunked transfer');
      }

      const player = new shaka.Player();
      await player.attach(videoElement);

      // Attach player to the window to make it easy to access in the JS console.
      window.player = player;

      // Listen for error events.
      player.addEventListener('error', ((error) => {
        console.error('Player error code', error.detail.code, 'object', error.detail);
      }));

      // Try to load a manifest.
      // This is an asynchronous process.
      try {
        await player.load(this.source.src);
        // This runs if the asynchronous load is successful.
        console.log('The video has now been loaded!');
      } catch (error) {
        console.error('Load error code', error.code, 'object', error);
      }
    }
  }
}
