import { Controller } from "stimulus";
import { useEventListener } from "stimulus-library";

export default class extends Controller {

  static targets = ['selectInput', 'customInput'];

  get selectedOption() {
    return this.selectInputTarget.options[this.selectInputTarget.selectedIndex];
  }

  get customInputWrapper() {
    return this.getWrapper(this.customInputTarget);
  }

  get selectInputWrapper() {
    return this.getWrapper(this.selectInputTarget);
  }

  connect() {
    if (this.hasSelectInputTarget) {
      useEventListener(this, this.selectInputTarget, 'change', this.toggleCustomInput);
      this.hideCustomInput();
    }
  }

  toggleCustomInput() {
    if (this.selectedOption.dataset.custom) {
      this.showCustomInput();
    }
  }

  showCustomInput() {
    this.customInputWrapper.classList.remove('hide');
    this.customInputWrapper.style.display = 'block';
    this.selectInputWrapper.remove();
  }

  hideCustomInput() {
    this.customInputWrapper.classList.add('hide');
  }

  getWrapper(el) {
    return el.closest('div.input');
  }

}