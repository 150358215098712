import { useMutation } from "stimulus-use";
import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["showMore"];

  static values = {
    limit: Number,
    truncated: Boolean,
  };

  get tableBody() {
    return this.element.tBodies[0];
  }

  get tableRows() {
    return Array.from(this.tableBody.rows);
  }

  get limit() {
    return this.hasLimitValue ? this.limitValue : 5;
  }

  get truncated() {
    return this.hasTruncatedValue ? this.truncatedValue : false;
  }

  set truncated(value) {
    this.truncatedValue = value;
  }

  initialize() {
    this.truncate = this.truncate.bind(this);
    this.expand = this.expand.bind(this);
  }

  connect() {
    useMutation(this, {childList: true, element: this.tableBody});

    requestAnimationFrame(() => {
      this.truncate();
      this.showMoreTarget.addEventListener("click", this.expand);
    });
  }

  showElement(row) {
    row.style.display = "";
  }

  hideElement(row) {
    row.style.display = "none";
  }

  re_truncate() {
    let hiddenEls = 0;
    this.tableRows.slice(0, this.limit).forEach((el) => {
      if (el !== this.showMoreTarget) {
        this.showElement(el);
      }
    });
    this.tableRows.slice(this.limit).forEach((el) => {
      if (el !== this.showMoreTarget) {
        hiddenEls += 1;
        this.hideElement(el);
      }
    });
    if (hiddenEls > 0) {
      this.showElement(this.showMoreTarget);
    }
  }

  truncate(event) {
    event?.preventDefault();
    this.truncated = true;
    if (this.tableRows.length >= this.limit) {
      this.tableRows.slice(this.limit).forEach((el) => {
        if (el !== this.showMoreTarget) {
          this.hideElement(el);
        }
      });
      this.showElement(this.showMoreTarget);
    } else {
      this.hideElement(this.showMoreTarget);
    }
  }

  expand(event) {
    this.truncated = false;
    event?.preventDefault();
    this.tableRows.slice(this.limit).forEach((el) => {
      if (el !== this.showMoreTarget) {
        this.showElement(el);
      }
    });
    this.hideElement(this.showMoreTarget);
  }

  mutate(entries) {
    if (this.truncated) {
      this.re_truncate();
    }
  }

}