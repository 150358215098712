import "element-remove";
import "proxy-polyfill";
import 'whatwg-fetch';
import "intersection-observer";
import { default as applyElementClosestPolyfill } from 'element-closest';
import smoothscroll from 'smoothscroll-polyfill';
import('weakmap-polyfill');

applyElementClosestPolyfill(window);
smoothscroll.polyfill();

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
