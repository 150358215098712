import { BaseController, useEventListener } from "stimulus-library";

export default class extends BaseController {

  static targets = ["fileInput", "metadataInput"];

  connect() {
    useEventListener(this, this.fileInputTarget, ["input", "change"], this.fileInputChanged);
  }

  getFileMetadata(file) {
    let nameParts = file.name.split(".");
    let format = nameParts.pop();
    let original_filename = nameParts.join(".");
    return {
      original_filename,
      format,
      bytes: file.size,
      type: "upload",
    };
  }

  fileInputChanged(event) {
    let files = event.target.files;
    if (files.length > 0) {
      let file = files[0];
      let metadata = this.getFileMetadata(file);
      this.metadataInputTarget.value = JSON.stringify(metadata);
    } else {
      this.metadataInputTarget.value = "";
    }
  }
}