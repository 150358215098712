import { Controller } from "stimulus";

export default class extends Controller {

  lastIndex = null;
  reverse = false;

  get tableHead() {
    return this.element.tHead;
  }

  get tableHeaders() {
    return Array.from(this.tableHead.rows[0].cells);
  }

  get tableBody() {
    return this.element.tBodies[0];
  }

  get tableRows() {
    return Array.from(this.tableBody.rows);
  }

  initialize() {
    this.sort = this.sort.bind(this);
  }

  connect() {
    requestAnimationFrame(() => {
      this.tableHeaders.forEach(cell => cell.addEventListener("click", this.sort));
    });
  }

  disconnect() {
    this.tableHeaders.forEach(cell => cell.removeEventListener("click", this.sort));
  }

  sort(event) {
    event.preventDefault();
    let headerCell = event.target;
    let headerCellIndex = this.indexOfHeaderCell(headerCell);
    this.sortByColumn(headerCellIndex);
  }

  indexOfHeaderCell(cell) {
    return this.tableHeaders.indexOf(cell);
  }

  sortByColumn(index) {
    this.reverse = index === this.lastIndex && !this.reverse;
    let frag = document.createDocumentFragment();
    let rows = this.tableRows;

    let newRows = rows.sort((row, otherRow) => {
      let cells = row.cells;
      let otherCells = otherRow.cells;

      if (row.dataset.sortTop || otherRow.dataset.sortBottom) {
        return -1;
      }
      if (row.dataset.sortBottom || otherRow.dataset.sortTop) {
        return 1;
      }

      let x = cells[index]?.dataset.value || cells[index]?.innerText || "";
      let y = otherCells[index]?.dataset.value || otherCells[index]?.innerText || "";

      let sortVal = x.localeCompare(y, "en", {sensitivity: "base", numeric: true, caseFirst: "upper"});

      if (this.reverse) {
        return sortVal > 0 ? -1 : 1;
      }
      return sortVal;
    });

    newRows.forEach(row => frag.appendChild(row));

    this.tableBody.innerHTML = "";
    this.tableBody.appendChild(frag);
    this.lastIndex = index;
  }

}
