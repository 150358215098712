import "core-js";
import * as ActiveStorage from "@rails/activestorage";
import { customElementIsRegistered } from "../lib/customElements";
import "@rails/actiontext";
import '../polyfills';
import '../controllers/public';
import '../controllers/shared';

if (!customElementIsRegistered("trix-toolbar")) {
  import("trix");
}

ActiveStorage.start();
