import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Appsignal from "@appsignal/javascript";
import { installErrorHandler } from "@appsignal/stimulus";

export function loadStimulusDefinitionsFromContexts(application, ...context) {
  context.forEach(context => application.load(definitionsFromContext(context)));
}

export function controllerMethod(controller, methodName) {
  const method = controller[methodName];
  if (typeof method == 'function') {
    return method;
  } else {
    return () => void 0;
  }
}

export const application = Application.start();
application.debug = process.env.NODE_ENV === 'development';

if (process.env.APPSIGNAL_JS_PUSH_API_KEY) {
  const appSignal = new Appsignal({key: process.env.APPSIGNAL_JS_PUSH_API_KEY || ""});
  installErrorHandler(appSignal, application);
}