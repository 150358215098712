import { installsTrixBehaviour } from "../../../lib/installsTrixBehaviour";
import { BaseController } from "stimulus-library";

export default class extends BaseController {

  initialize() {
    this.preventUploads = this.preventUploads.bind(this);
  }

  connect() {
    installsTrixBehaviour(this);
  }

  install({toolbar}) {
    toolbar.querySelectorAll('.trix-button-group.trix-button-group--file-tools').forEach(el => el.style.display = 'none');
    this.el.addEventListener('trix-file-accept', this.preventUploads);
  }

  uninstall({toolbar}) {
    toolbar.querySelectorAll('.trix-button-group.trix-button-group--file-tools').forEach(el => el.style.display = null);
    this.el.removeEventListener('trix-file-accept', this.preventUploads);
  }

  preventUploads(e) {
    e.preventDefault();
  }
}