import {Controller} from 'stimulus';

export default class RemoveController  extends Controller {

  static get targets() {
    return ["remove"];
  }

  connect() {
  }

  remove() {
    let element = this.hasRemoveTarget ? this.removeTarget : this.element;
    element.remove();
  }
}