import AsyncBlockController from './async_block_controller';

export default class LazyBlockController extends AsyncBlockController {

  initialize() {
    this.handleVisibility = this.handleVisibility.bind(this);
  }

  connect() {
    let element = this.element;

    if ("IntersectionObserver" in window) {
      this.intersectionObserver = new IntersectionObserver(this.handleVisibility, {threshold: 0.3});
      this.intersectionObserver.observe(element);
    } else {
      // Client doesn't support intersection observer, fallback to pre-loading
      this.loadContent();
    }
  }

  handleVisibility(entries, observer) {
    let element = this.element;
    if (!this.hasEndpointValue) {
      return;
    }
    entries.forEach((entry) => {
      if (entry.target === element && entry.isIntersecting) {
        this.loadContent();
        observer.unobserve(element);
        observer.disconnect();
      }
    });
  }

}