import { EventBus } from "../../lib/eventBus";
import { BaseController } from "stimulus-library";

export default class extends BaseController {

  static targets = [
    'confirm',
    'next',
    'timer'
  ];

  get allMediaViewed() {
    return Object.values(this.media).every((el) => el);
  }

  get allConfirmed() {
    return this.confirmTargets.every(el => el.checked);
  }

  initialize() {
    this.swallowEventsIfLocked = this.swallowEventsIfLocked.bind(this);
    this.checkLocks = this.checkLocks.bind(this);
  }

  connect() {
    this.media = {};
    this.locked = false;
    if (this.hasConfirmTarget) {
      this.confirmTargets.forEach(el => el.addEventListener('change', this.checkLocks));
      this.checkLocks();
    } else {
    }


    EventBus.on('media:connected', ({id, video_el}) => {
      const that = this;
      let prevTime = 0;

      // Set initial timer (video duration isn't always immediately available)
      const interval = window.setInterval(() => {
        if(!isNaN(video_el.duration)) { that.setTimer(video_el); window.clearInterval(interval); }
      }, 50);

      // Update timer on time updates (during play)
      video_el.addEventListener('timeupdate', () => {
        if(!video_el.seeking)
        {
          if(prevTime < video_el.currentTime)
            prevTime = video_el.currentTime;
          that.setTimer(video_el); // Update remaining time
        }
      });

      if(video_el.dataset.ffDisabled === 'true')
      {
        // Prevent fast-forwarding
        video_el.addEventListener('seeking', () => {
          if(video_el.currentTime - prevTime > 0.01)
            video_el.currentTime = prevTime;
        });
      }

      // Add media ID to object (false == not finished)
      this.media[id] = false;
      this.lock();
    });
    EventBus.on('media:disconnected', ({id}) => {
      delete this.media[id];
      this.checkLocks();
    });
    EventBus.on('media:finished', ({id}) => {
      this.media[id] = true; // true == finished
      this.checkLocks();
    });

    this.nextTarget.addEventListener('click', this.swallowEventsIfLocked);
  }

  disconnect() {
    if (this.hasConfirmTarget) {
      this.confirmTargets.forEach(el => el.removeEventListener('click', this.checkLocks));
    }
  }

  checkLocks() {
    if (this.allConfirmed && this.allMediaViewed) {
      this.unlock();
    } else {
      this.lock();
    }
  }

  setTimer(video_el, time = null) {
    if (this.hasTimerTarget) {
      const timer = video_el.closest(".induction-section.video").querySelector(".timer");
      let seconds = (time) ? time.seconds : (video_el.duration - video_el.currentTime);
      let minutes = (time) ? time.minutes : (Math.floor(seconds/60));
      if(!time) seconds = Math.floor(seconds - (minutes*60));
      timer.innerText = `Time remaining: ${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  }

  swallowEventsIfLocked(event) {
    if (this.locked && event) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  }

  lock(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.locked = true;
    this.nextTarget.setAttribute('disabled', "true");
  }

  unlock(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.locked = false;
    this.nextTarget.removeAttribute('disabled');
  }
}
