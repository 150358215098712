import { Controller } from 'stimulus';
import { EventBus } from "../../lib/eventBus";
import { v4 as uuid } from 'uuid';

export default class extends Controller {

  static targets = ['media'];

  disconnect() {
    EventBus.emit('media:disconnected', {id: this.id});
  }

  connect() {
    this.id = uuid();
    EventBus.emit('media:connected', {id: this.id, video_el: this.mediaTarget});

    this.mediaTarget.addEventListener('play', () => EventBus.emit('media:started', {id: this.id}));
    this.mediaTarget.addEventListener('ended', () => EventBus.emit('media:finished', {id: this.id}));
    this.mediaTarget.addEventListener('error', () => EventBus.emit('media:error', {id: this.id}));
    this.mediaTarget.addEventListener('abort', () => EventBus.emit('media:abort', {id: this.id}));
  }

}
