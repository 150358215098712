import { installsTrixBehaviour } from "../../../lib/installsTrixBehaviour";
import { BaseController } from "stimulus-library";

export default class extends BaseController {

  connect() {
    installsTrixBehaviour(this);
  }

  install({toolbar}) {
    toolbar.querySelectorAll('.trix-button--icon-heading-1').forEach(el => el.style.display = 'none');
  }

  uninstall({toolbar}) {
    toolbar.querySelectorAll('.trix-button--icon-heading-1').forEach(el => el.style.display = null);
  }

}