import { DirectUpload } from '@rails/activestorage';
import { BaseController, useEventListener } from 'stimulus-library';

export default class extends BaseController {

  static targets = [
    'input',
    'label',
    'text',
    'icon',
    'fileName',
    'fileTypeIcon',
    'fileTypeIconWrapper',
    'preview',
    'progress',

    'previousUploadWrapper',
    'clearPreviousUploadLink',
    'clearPreviousUploadInput',
  ];
  isDirectUpload = false;
  directUploadUrl = undefined;
  directUploadXhr = null;

  initialize() {
    this.valueChanged = this.valueChanged.bind(this);
    this.togglePreviousUpload = this.togglePreviousUpload.bind(this);
    this.clearIfHasFile = this.clearIfHasFile.bind(this);
  }

  connect() {
    let inputDataset = this.inputTarget.dataset;
    let directUploadUrl = inputDataset.directUploadUrl;
    this.isDirectUpload = directUploadUrl !== undefined && directUploadUrl !== '';
    this.directUploadUrl = directUploadUrl ? directUploadUrl : '';

    let stopPropagation = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    let addClass = (e) => {
      stopPropagation(e);
      this.labelTarget.classList.add('drag-event-ongoing');
    };

    let removeClass = (e) => {
      stopPropagation(e);
      this.labelTarget.classList.remove('drag-event-ongoing');
    };

    if (this.hasClearPreviousUploadLink) {
      this.clearPreviousUploadLink.addEventListener('click', this.togglePreviousUpload);
    }
    this.labelTarget.addEventListener('click', this.clearIfHasFile);
    useEventListener(this, this.inputTarget, ['change', 'input'], this.valueChanged, {debounce: 50});
    this.textTarget.innerText = this.defaultTextLabel();

    if (this.supportsDragNDrop()) {
      this.element.addEventListener('drag', stopPropagation);
      this.element.addEventListener('dragstart', stopPropagation);
      this.element.addEventListener('drop', (e) => {
        removeClass(e);
        let dataTransfer = e.dataTransfer;

        if (dataTransfer !== null) {
          if (this.filesSupported(e)) {
            this.inputTarget.files = dataTransfer.files;
            this.dispatch(this.inputTarget, 'change');
            this.dispatch(this.inputTarget, 'input');
          } else {
            e.preventDefault();
            alert('Please only upload a file in one of the accepted formats.');
          }
        }
      });

      this.element.addEventListener('dragover', addClass);
      this.element.addEventListener('dragenter', addClass);
      this.element.addEventListener('dragleave', removeClass);
      this.element.addEventListener('dragend', removeClass);
    }
  }

  clearIfHasFile(event) {
    // Handle clearing of the input
    if (this.inputTarget.value !== '') {
      event.preventDefault();
      this.inputTarget.value = '';
      this.valueChanged();
    }
  }

  defaultTextLabel() {
    let multiple = this.inputTarget.multiple;
    let plurality = multiple ? 'file(s)' : 'a file';
    return this.supportsDragNDrop() ? `Drag and Drop / Click to select ${plurality}` : `Click to select ${plurality}`;
  }

  filesSupported(e) {
    let acceptedTypes = this.inputTarget.accept ? this.inputTarget.accept.split(',') : null;
    let wildcardTypes = acceptedTypes ? acceptedTypes.filter(type => type.includes('/*')) : null;
    let files = Array.from(e.dataTransfer.files);
    if (acceptedTypes) {
      return files.every(file => acceptedTypes.includes(file.type) || wildcardTypes.some(type => file.type.includes(type.substr(0, type.indexOf('/*')))));
    } else {
      return true;
    }
  }

  valueChanged() {
    let files = this.inputTarget.files;
    if (files == null || files.length === 0) {
      this.textTarget.innerText = this.defaultTextLabel();
      this.hideElement(this.fileNameTarget);
      this.fileNameTarget.innerText = '';
      this.setUploadIcon();
      this.hideImagePreview();
      this.hideFileTypeIcon();
      this.showPreviouslyUploaded();

      if (this.isDirectUpload) {
        this.hideElement(this.progressTarget);
      }
    } else {
      let filesArr = Array.from(files);
      let plural = filesArr.length > 1;
      let singular = !plural;

      this.directUploadXhr?.abort();
      this.directUploadXhr = null;
      this.setClearIcon();
      this.hidePreviouslyUploaded();

      this.textTarget.innerText = `${plural ? 'Files' : 'File'} selected, click again to remove.`;
      this.showElement(this.fileNameTarget);
      this.fileNameTarget.style.display = "block";

      let wrapSpans = (file) => `<span class='file'>${file.name}</span>`;
      this.fileNameTarget.innerHTML = filesArr.map((file) => wrapSpans(file)).join('');

      if (this.isDirectUpload) {
        console.log('Direct Upload');
        this.showElement(this.progressTarget);
        this.progressTarget.style.display = "block";
        filesArr.forEach((file) => this.uploadFile(file));
      } else {
        console.log('Not Direct Upload');
      }
      if (files.length === 1) {
        let file = files[0];
        this.showPreview(file);
      }
    }
  }

  clearPreviousUpload(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.insertHiddenClearInput();
    this.clearPreviousUploadLinkTarget.innerText = 'Previously uploaded file will be cleared. Click to cancel';
  }

  restorePreviousUpload(event = null) {
    if (event) {
      event.preventDefault();
    }
    this.removeHiddenClearInput();
    this.clearPreviousUploadLinkTarget.innerText = 'Clear previously uploaded file';
  }

  togglePreviousUpload(event = null) {
    if (event) {
      event.preventDefault();
    }
    if (this.hasPreviousUploadWrapperTarget && !this.hasClearPreviousUploadInputTarget) {
      this.clearPreviousUpload();
    } else if (this.hasPreviousUploadWrapperTarget && this.hasClearPreviousUploadInputTarget) {
      this.restorePreviousUpload();
    }
  }

  insertHiddenClearInput() {
    let magicValue = this.clearPreviousUploadLinkTarget.dataset.with || '_destroy';

    if (this.hasPreviousUploadWrapperTarget && !this.hasClearPreviousUploadInputTarget) {
      const hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.name = this.inputTarget.name;
      hiddenInput.setAttribute('value', magicValue);
      hiddenInput.dataset.target = `${this.identifier}.clearPreviousUploadInput`;
      this.inputTarget.insertAdjacentElement('afterend', hiddenInput);
    }
  }

  removeHiddenClearInput() {
    if (this.hasClearPreviousUploadInputTarget) {
      this.clearPreviousUploadInputTarget.remove();
    }
  }

  uploadFile(file) {
    console.log('uploading file', file);
    // your form needs the file_field direct_upload: true, which
    //  provides data-direct-upload-url
    const upload = new DirectUpload(file, this.directUploadUrl, this);
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    // Add an appropriately-named hidden input to the form with a
    //  value of blob.signed_id so that the blob ids will be
    //  transmitted in the normal upload flow
    hiddenInput.name = this.inputTarget.name;
    this.element.insertAdjacentElement('beforeend', hiddenInput);
    this.resetProgressBar();
    this.showProgressBar();
    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        this.updateProgressText('Something went wrong and your file could not be uploaded...', 'error');
        hiddenInput.remove();
        this.dispatch(this.inputTarget, 'direct-upload:error', {detail: {error}});
      } else {
        hiddenInput.setAttribute('value', blob.signed_id);
        this.resetProgressBar();
        this.dispatch(this.inputTarget, 'direct-upload:success', {detail: {blob}});
      }
    });
  }

  supportsDragNDrop() {
    let div = document.createElement('div');
    return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
  }

  showPreview(file) {
    if (this.supportsImagePreview() && file.type.includes('image')) {
      this.showImagePreview(file);
    } else {
      this.showFileTypeIcon(file);
    }
  }

  supportsImagePreview() {
    return 'FileReader' in window;
  }

  showImagePreview(file) {
    let reader = new FileReader();
    reader.onerror = (e) => {
      console.log("Err", e);
      this.hideImagePreview();
    };
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        this.showElement(this.previewTarget);
        this.previewTarget.style.display = "block";
        this.previewTarget.src = e.target.result;
      } else {
        this.hideImagePreview();
      }
    };
    reader.readAsDataURL(file); // convert to base64 string
  }

  hideImagePreview() {
    this.hideElement(this.previewTarget);
    this.previewTarget.src = '';
  }

  showFileTypeIcon(file) {
    this.showElement(this.fileTypeIconWrapperTarget);
    this.fileTypeIconWrapperTarget.style.display = "block";
    let [fileType, fileSubtype] = file.type.split('/', 2);
    let fileIcon = 'fa-file-upload';
    switch (fileType) {
      case 'image':
        fileIcon = 'fa-file-image';
        break;

      case 'audio':
        fileIcon = 'fa-file-audio';
        break;

      case 'application':
        if (fileSubtype === 'pdf') {
          fileIcon = 'fa-file-pdf';
        } else {
          fileIcon = 'fa-file-code';
        }
        break;
    }
    this.fileTypeIconTarget.className = `fa fa-lg ${fileIcon}`;
  }

  hideFileTypeIcon() {
    // Replace all classes
    this.hideElement(this.fileTypeIconWrapperTarget);
  }

  showProgressBar() {
    this.showElement(this.progressTarget);
    this.progressTarget.style.display = "block";
  }

  hideProgressBar() {
    this.hideElement(this.progressTarget);
  }

  resetProgressBar() {
    this.updateProgressText('Please wait while your file is uploaded...');
    this.updateProgressBar(1);
    this.hideProgressBar();
  }

  updateProgressText(text, textClass = '') {
    let el = this.progressTarget.querySelector('p');
    el.innerText = text;
    el.className = textClass;
  }

  updateProgressBar(progress) {
    this.progressTarget.querySelector('.progress-meter').style.width = `${progress}%`;
  }

  setUploadIcon() {
    this.iconTarget.classList.remove('fa-times-circle');
    this.iconTarget.classList.add('fa-file-upload');
  }

  setClearIcon() {
    this.iconTarget.classList.remove('fa-file-upload');
    this.iconTarget.classList.add('fa-times-circle');
  }

  showPreviouslyUploaded() {
    if (this.hasPreviousUploadWrapperTarget) {
      this.showElement(this.previousUploadWrapperTarget);
      this.previousUploadWrapperTarget.style.display = "block";
    }
  }

  hidePreviouslyUploaded() {
    if (this.hasPreviousUploadWrapperTarget) {
      this.hideElement(this.previousUploadWrapperTarget);
    }
  }

  // DirectUpload delegate
  uploadRequestDidProgress(event) {
    const progress = event.loaded / event.total * 100;
    if (progress) {
      if (progress === 100) {
        this.updateProgressText('Finalising, this could take a few moments....');
      }
      this.updateProgressBar(progress);
    }
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    this.directUploadXhr = xhr;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.directUploadXhr = xhr;
    xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }

  hideElement(el) {
    el.classList.add('hide');
    el.style.display = null;
  }

  showElement(el) {
    el.classList.remove('hide');
    el.style.display = null;
  }
}
